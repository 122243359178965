<script setup lang="ts">
import { useRestorePassword } from "~/composible/useRestorePassword";
import ForgotPasswordForm from "~/components/auth/restore-password/ForgotPasswordForm.vue";
import ConfirmCodeForm from "~/components/auth/restore-password/ConfirmCodeForm.vue";
import SetPasswordForm from "~/components/auth/restore-password/SetPasswordForm.vue";

const { restoreData, clearRestoreData } = useRestorePassword();

onUnmounted(() => clearRestoreData());
</script>

<template>
  <div class="flex flex-col gap-[56px]">
    <div class="text-center justify-center">
      <nuxt-img
          src="/img/illustration-envelop.webp"
          width="80px"
          height="80px"
          class="mb-4 mx-auto"
      />
      <h4 class="text-3xl font-bold mb-3">Restore password</h4>
      <p class="text-lg font-light leading-5 mb-4">We will send an email to your inbox to reset your password</p>
    </div>

    <transition name="slide-fade" mode="out-in">
      <forgot-password-form v-if="!restoreData.email" />
      <confirm-code-form v-else-if="!restoreData.token" />
      <set-password-form v-else-if="restoreData.token" />
    </transition>

    <p class="text-center">
      If you continue, you agree to our<br />
      <a href="/public/web-terms-of-use.html" class="link">Terms of Use</a>
      and
      <a href="/public/web-privacy-policy.html" class="link">Privacy Policy</a>
    </p>
  </div>
</template>

<style scoped>
.link {
  @apply text-violet-800 dark:text-primary border-b-[1px] border-violet-800 dark:border-primary
}

.slide-fade-leave-active {
  transition: all .2s ease;
}

.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateX(10%);
  opacity: 0;
}
</style>