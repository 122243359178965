<script setup lang="ts">
interface IProps {
  modelValue: string;
  placeholder?: string | null;
  disabled?: boolean;
  required?: boolean;
  minLength?: number;
}

const props = withDefaults(defineProps<IProps>(), {
  disabled: false,
  required: false,
  minLength: 0
});

const emit = defineEmits(['update:modelValue']);

const inputValue = ref(props.modelValue);
const showPassword = ref(false);

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};

watch(() => props.modelValue, (newValue) => {
  inputValue.value = newValue;
});

watch(inputValue, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>

<template>
  <div class="relative">
    <input
        id="password"
        :type="showPassword ? 'text' : 'password'"
        v-model="inputValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :minlength="minLength"
        class="px-3 py-4 max-md:py-3 pr-12 w-full border text-zinc-900 dark:text-white border-primary bg-gray-100 dark:bg-zinc-950 rounded-xl focus:outline-none"
    />
    <button
        @click="toggleShowPassword"
        type="button"
        class="absolute right-4 top-4"
    >
      <svg v-if="showPassword" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
          <g id="Group_2">
            <path id="Path" fill-rule="evenodd" clip-rule="evenodd" d="M3.11799 12.467C2.96099 12.176 2.96099 11.823 3.11799 11.532C5.00999 8.033 8.50499 5 12 5C15.495 5 18.99 8.033 20.882 11.533C21.039 11.824 21.039 12.177 20.882 12.468C18.99 15.967 15.495 19 12 19C8.50499 19 5.00999 15.967 3.11799 12.467Z" stroke="#8B8B8B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Path_2" fill-rule="evenodd" clip-rule="evenodd" d="M14.1213 9.87868C15.2929 11.0502 15.2929 12.9497 14.1213 14.1213C12.9497 15.2929 11.0502 15.2929 9.87868 14.1213C8.70711 12.9497 8.70711 11.0502 9.87868 9.87868C11.0502 8.70711 12.9497 8.70711 14.1213 9.87868Z" fill="#8B8B8B"/>
          </g>
        </g>
      </svg>

      <svg v-else width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.11804 13.1338C5.01004 16.6338 8.50504 19.6668 12 19.6668C15.495 19.6668 18.99 16.6338 20.882 13.1348" stroke="#8B8B8B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 21.6667V19.6667" stroke="#8B8B8B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.4144 19.0809L18.0002 17.6667M4.58594 19.0809L6.00015 17.6667" stroke="#8B8B8B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>
</template>

<style scoped>

</style>