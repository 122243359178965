<script setup lang="ts">

import { useAuth } from "~/composible/useAuth";
import { SocialProvider } from "~/types/socials";
import { useFirebase } from "~/composible/useFirebase";

const { $toast } = useNuxtApp();
const step = useState('auth-step');
const appConfig = useAppConfig();
const { loginWithSocials, closeAuthModal } = useAuth();
const { signInWithGoogle, signInWithFacebook, signInWithApple } = useFirebase();

const goRegister = () => step.value = 'register';
const goLogin = () => step.value = 'login';

const loading = ref(false);

const loginWithGoogle = async () => {
  try {
    loading.value = true;
    const user = await signInWithGoogle();

    await loginWithSocials(SocialProvider.Google, {
      identifier: user.providerData[0].uid,
      email: user.providerData[0].email ?? user.email,
      name: user.providerData[0].displayName ?? user.displayName,
      avatar: user.providerData[0].photoURL ?? null,
    });

    closeAuthModal();
  } catch (error: any) {
    console.error('Error signing in: ', error)

    if (error.data?.message) {
      $toast.error(error.data.message);
    }
  } finally {
    loading.value = false;
  }
}

const loginWithFacebook = async () => {
  try {
    loading.value = true;
    const user = await signInWithFacebook()

    await loginWithSocials(SocialProvider.Facebook, {
      identifier: user.providerData[0].uid,
      email: user.providerData[0].email ?? user.email,
      name: user.providerData[0].displayName ?? user.displayName,
      avatar: user.providerData[0].photoURL ?? null,
    });

    closeAuthModal();
  } catch (error: any) {
    console.error('Error signing in: ', error);

    if (error.data?.message) {
      $toast.error(error.data.message);
    }
  } finally {
    loading.value = false;
  }
}

const loginWithApple = async () => {
  try {
    loading.value = true;
    const user = await signInWithApple()

    await loginWithSocials(SocialProvider.Apple, {
      identifier: user.providerData[0].uid,
      email: user.providerData[0].email ?? user.email,
      name: user.providerData[0].displayName ?? user.displayName,
      avatar: user.providerData[0].photoURL ?? null,
    });

    closeAuthModal();
  } catch (error: any) {
    console.error('Error signing in: ', error)

    if (error.data?.message) {
      $toast.error(error.data.message);
    }
  } finally {
    loading.value = false;
  }
}
</script>

<template>
  <div class="flex flex-col text-center justify-center items-center">
    <h4 class="text-3xl font-bold mb-3">Join FictionMe</h4>
    <p class="text-lg font-light leading-5 mb-4">Create or log into your account to access terrific novels and protect your data</p>

    <nuxt-img
        src="/img/signup-illustration.webp"
        width="200"
        height="200"
        class="mb-7"
    />

    <div class="flex flex-col w-full gap-2 mb-10">
      <button @click.prevent="loginWithApple" :disabled="loading" class="button bg-[#272727] text-white dark:border border-zinc-600">
        <img src="@/assets/img/apple.svg" width="24" alt="apple" />
        Continue with Apple
      </button>
      <button @click.prevent="loginWithFacebook" :disabled="loading" class="button bg-[#1877F2] text-white">
        <img src="@/assets/img/facebook.svg" width="24" alt="facebook" />
        Continue with Facebook
      </button>
      <button @click.prevent="loginWithGoogle" :disabled="loading" class="button bg-[#F9F9F9] text-zinc-900">
        <img src="@/assets/img/google.svg" width="24" alt="google" />
        Continue with Google
      </button>
      <p class="text-gray-300">or</p>
      <button @click.prevent="goRegister" :disabled="loading" class="button border-[1px] border-primary text-primary">
        Sign Up with Email
      </button>
    </div>

    <div class="flex flex-col w-full gap-2">
      <p>Already have an account <a @click.prevent="goLogin" href="#" class="link">Sign In</a></p>
      <p>Are you an author? <a :href="appConfig.authorCabinetUrl" class="link">Sign In as Author</a></p>
    </div>
  </div>
</template>

<style scoped>
  .button {
    @apply items-center justify-center py-4 px-6 rounded-2xl flex gap-4 w-full
  }

  .link {
    @apply text-violet-800 dark:text-primary border-b-[1px] border-violet-800 dark:border-primary
  }
</style>