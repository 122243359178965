<script setup lang="ts">
import { useRestorePassword } from "~/composible/useRestorePassword";
import FInput from "~/components/forms/Input.vue";

const { $toast } = useNuxtApp();
const { setEmail, forgotPassword } = useRestorePassword();

const form = reactive({
  data: {
    email: "",
  },
  error: null,
  loading: false,
});

async function resetHandler() {
  try {
    form.error = null;
    form.loading = true;

    await forgotPassword(form.data.email);
    setEmail(form.data.email);
  } catch (error: any) {

    if (error.data.message) {
      $toast.error(error.data.message);
      form.error = error.data.message;
    }
  } finally {
    form.loading = false;
  }
}
</script>

<template>
  <form class="flex flex-col text-left" @submit.prevent="resetHandler">
    <div class="mb-4">
      <label for="email">Email</label>
      <forms-input
          id="email"
          v-model="form.data.email"
          type="email"
          placeholder="Enter your email"
          autofocus
          required
      />
    </div>

    <div class="my-10">
      <button
          type="submit"
          :disabled="form.loading"
          class="items-center justify-center py-4 px-6 rounded-2xl flex gap-4 w-full bg-fm-gradient hover:bg-fm-gradient-active text-white"
      >
        {{ form.loading ? "Please wait..." : "Send"}}
      </button>
    </div>
  </form>
</template>

<style scoped>
label {
  @apply text-lg font-semibold inline-block mb-2
}

input {
  @apply px-3 py-4 w-full border !text-zinc-900 border-primary bg-gray-100 rounded-xl focus:outline-none
}
</style>