<script setup lang="ts">
import { useRestorePassword } from "~/composible/useRestorePassword";
import TogglePassword from "~/components/forms/TogglePassword.vue";

const { $toast } = useNuxtApp();
const { restoreData, resetPassword } = useRestorePassword();
const step = useState('auth-step', () => 'default');

const form = reactive({
  data: {
    password: "",
    passwordConfirmation: "",
  },
  error: null,
  loading: false,
});

async function resetHandler() {
  try {
    form.error = null;
    form.loading = true;

    await resetPassword(restoreData.token, form.data.password, form.data.passwordConfirmation);

    step.value = 'login';
  } catch (error: any) {
    if (error.data.message) {
      $toast.error(error.data.message);
      form.error = error.data.message;
    }
  } finally {
    form.loading = false;
  }
}

const isPasswordValid = computed(() => {
  const password = form.data.password;
  const passwordConfirmation = form.data.passwordConfirmation;

  return password && passwordConfirmation && password === passwordConfirmation;
})
</script>

<template>
  <form class="flex flex-col text-left" @submit.prevent="resetHandler">
    <div class="mb-4">
      <label for="password">Password</label>
      <toggle-password
          id="password"
          v-model="form.data.password"
          placeholder="Enter password"
          autofocus
          required
      />
    </div>

    <div class="mb-4">
      <label for="password">Confirm password</label>
      <toggle-password
          id="password"
          v-model="form.data.passwordConfirmation"
          placeholder="Enter password"
          required
      />
    </div>

    <div class="my-10">
      <button
          type="submit"
          :disabled="form.loading || !isPasswordValid"
          class="items-center justify-center py-4 px-6 rounded-2xl flex gap-4 w-full text-white"
          :class="isPasswordValid ? 'bg-fm-gradient hover:bg-fm-gradient-active' : 'bg-gray-600'"
      >
        {{ form.loading ? "Please wait..." : "Reset Password" }}
      </button>
    </div>
  </form>
</template>

<style scoped>
label {
  @apply text-lg font-semibold inline-block mb-2
}

input {
  @apply px-3 py-4 w-full border !text-zinc-900 border-primary bg-gray-100 rounded-xl focus:outline-none
}
</style>