<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  length: {
    type: Number,
    required: true,
    default: 4,
  }
});

const emit = defineEmits(['update:modelValue']);

const pinArray = ref(Array(props.length).fill(''));
const pinInputs = ref<(HTMLInputElement | null)[]>([]);

watch(
    () => props.modelValue,
    (newValue) => {
      pinArray.value = newValue.split('').concat(Array(props.length - newValue.length).fill('')).slice(0, props.length);
    },
    { immediate: true }
);

const handleInput = (event: Event, index: number) => {
  const input = event.target as HTMLInputElement;
  pinArray.value[index] = input.value;

  emit('update:modelValue', pinArray.value.join(''));

  if (input.value.length === 1 && index < props.length - 1) {
    pinInputs.value[index + 1]?.focus();
  }
};

const handleBackspace = (event: KeyboardEvent, index: number) => {
  if (event.key === 'Backspace' && index > 0 && !pinArray.value[index]) {
    pinInputs.value[index - 1]?.focus();
  }
};

const handlePaste = (event: ClipboardEvent) => {
  const paste = event.clipboardData?.getData('text');
  if (paste) {
    const pasteArray = paste.split('').slice(0, props.length);
    for (let i = 0; i < props.length; i++) {
      pinArray.value[i] = pasteArray[i] || '';
    }
    emit('update:modelValue', pinArray.value.join(''));

    pinInputs.value[pasteArray.length - 1]?.focus();
  }
  event.preventDefault();
};
</script>

<template>
  <div class="flex space-x-2">
    <input
        v-for="(digit, index) in pinArray"
        :key="index"
        maxlength="1"
        class="px-3 py-4 max-md:py-3 w-12 max-md:w-10 text-center border text-zinc-900 dark:!text-white border-primary bg-gray-100 dark:bg-zinc-950 rounded-xl focus:outline-none"
        :value="digit"
        @input="handleInput($event, index)"
        @keydown.backspace="handleBackspace($event, index)"
        @paste="handlePaste($event)"
        ref="pinInputs"
    />
  </div>
</template>

<style scoped>

</style>