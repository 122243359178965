<script setup lang="ts">
import { useAuth } from "~/composible/useAuth";

const { register, closeAuthModal } = useAuth();
const { $toast } = useNuxtApp();

const step = useState('auth-step');

const form = reactive({
  data: {
    email: "",
    password: "",
  },
  error: null,
  loading: false,
});

async function registerHandler() {
  try {
    form.error = null;
    form.loading = true;

    await register(form.data.email, form.data.password);

    closeAuthModal();
  } catch (error: any) {

    if (error.data.message) {
      $toast.error(error.data.message);
      form.error = error.data.message;
    }
  } finally {
    form.loading = false;
  }
}

const goLogin = () => step.value = 'login';
</script>

<template>
  <div class="flex flex-col gap-[56px]">
    <div class="text-center">
      <h4 class="text-3xl font-bold mb-3">Sign Up</h4>
      <p class="text-lg font-light leading-5 mb-4">Create FictionMe Account</p>
    </div>

    <form class="flex flex-col text-left" @submit.prevent="registerHandler">
      <div class="mb-4">
        <label for="email">Email</label>
        <forms-input
            v-model="form.data.email"
            type="email"
            placeholder="Enter your email"
            autofocus
            required
        />
      </div>
      <div>
        <label for="password">Password</label>
        <forms-toggle-password
            v-model="form.data.password"
            placeholder="6+ characters"
            :min-length="6"
            required
        />
      </div>

      <div class="my-10">
        <button
            type="submit"
            :disabled="form.loading"
            class="items-center justify-center py-4 px-6 rounded-2xl flex gap-4 w-full bg-fm-gradient hover:bg-fm-gradient-active text-white"
        >
          {{ form.loading ? "Please wait..." : "Sign Up"}}
        </button>
      </div>

      <div class="flex flex-col w-full gap-2 items-center">
        <p>Already have an account <a @click.prevent="goLogin" href="#" class="link">Sign In</a></p>
      </div>
    </form>

    <p class="text-center">
      If you continue, you agree to our<br />
      <a href="/public/web-terms-of-use.html" class="link">Terms of Use</a>
      and
      <a href="/public/web-privacy-policy.html" class="link">Privacy Policy</a>
    </p>
  </div>
</template>

<style scoped>
label {
  @apply text-lg font-semibold inline-block mb-2
}

input {
  @apply px-3 py-4 w-full border !text-zinc-900 border-primary bg-gray-100 rounded-xl focus:outline-none
}

.link {
  @apply text-violet-800 dark:text-primary border-b-[1px] border-violet-800 dark:border-primary
}
</style>