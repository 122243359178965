
const restoreData = reactive<{email: string, code: string, token:string}>({
    email: "",
    code: "",
    token: "",
})

export const useRestorePassword = () => {
    const setEmail = (email: string) => {
        restoreData.email = email
    };

    const setCode = (code: string) => {
        restoreData.code = code
    };

    const setToken = (token: string) => {
        restoreData.token = token
    };

    const clearRestoreData = () => {
        restoreData.email = "";
        restoreData.code = "";
        restoreData.token = "";
    };

    const forgotPassword = async (email: string) => {
        await $fetch("/web-api/restore-password/forgot-password", {
            method: "POST",
            body: {
                email
            }
        });
    }

    const confirmCode = async (email: string, code: string) => {
        const response = await $fetch<{data: {reset_token: string}}>("/web-api/restore-password/confirm-code", {
            method: "POST",
            body: {
                email,
                code
            }
        });

        return response.data
    }

    const resetPassword = async (reset_token: string, password: string, password_confirmation: string) => {
        await $fetch("/web-api/restore-password/reset-password", {
            method: "POST",
            body: {
                reset_token,
                password,
                password_confirmation
            }
        });
    }

    return {
        restoreData,
        setEmail,
        setCode,
        setToken,
        forgotPassword,
        confirmCode,
        resetPassword,
        clearRestoreData
    }
}