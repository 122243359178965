import { initializeApp } from 'firebase/app'
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from 'firebase/auth'

export const useFirebase = () => {
  const config = useRuntimeConfig();

  const firebaseConfig = {
    apiKey: config.public.firebase.apiKey,
    authDomain: config.public.firebase.authDomain,
    projectId: config.public.firebase.projectId,
    storageBucket: config.public.firebase.storageBucket,
    messagingSenderId: config.public.firebase.messagingSenderId,
    appId: config.public.firebase.appId,
    measurementId: config.public.firebase.measurementId,
  };

  const firebaseApp = initializeApp(firebaseConfig)
  const auth = getAuth(firebaseApp);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('email');
    const result =  await signInWithPopup(auth, provider);

    return result.user;
  };

  const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    provider.addScope('email');
    const result =  await signInWithPopup(auth, provider);

    return result.user;
  };

  const signInWithApple = async () => {
    const provider = new OAuthProvider('apple.com');
    const result =  await signInWithPopup(auth, provider);

    return result.user;
  }

  return {
    app: firebaseApp,
    auth,
    signInWithGoogle,
    signInWithFacebook,
    signInWithApple
  }
}