<script setup lang="ts">
import { useRestorePassword } from "~/composible/useRestorePassword";
import PinInput from "~/components/forms/PinInput.vue";

const { $toast } = useNuxtApp();
const { restoreData, setCode, setToken, confirmCode } = useRestorePassword();

const CODE_LENGTH = 6;

const form = reactive({
  data: {
    code: '',
  },
  error: null,
  loading: false,
});

async function resetHandler() {
  try {
    form.error = null;
    form.loading = true;

    const response = await confirmCode(restoreData.email, form.data.code);
    setCode(form.data.code);
    console.log(response.reset_token);
    setToken(response.reset_token);
  } catch (error: any) {
    console.error(error);

    if (error.data.message) {
      $toast.error(error.data.message);
      form.error = error.data.message;
    }
  } finally {
    form.loading = false;
  }
}

const isCodeValid = computed(() => {
  const regex = /^\d{6}$/;
  return regex.test(form.data.code);
})
</script>

<template>
  <form class="flex flex-col text-left" @submit.prevent="resetHandler">
    <div class="mb-4">
      <label class="text-center w-full" for="email">Code</label>
      <div class="flex justify-center">
        <pin-input
            id="code"
            v-model="form.data.code"
            :length="CODE_LENGTH"
        />
      </div>
    </div>

    <div class="my-10">
      <button
          type="submit"
          :disabled="form.loading || !isCodeValid"
          class="items-center justify-center py-4 px-6 rounded-2xl flex gap-4 w-full text-white"
          :class="isCodeValid ? 'bg-fm-gradient hover:bg-fm-gradient-active' : 'bg-gray-600'"
      >
<!--        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g stroke="currentColor"><circle cx="12" cy="12" r="9.5" fill="none" stroke-linecap="round" stroke-width="3"><animate attributeName="stroke-dasharray" calcMode="spline" dur="1.5s" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" keyTimes="0;0.475;0.95;1" repeatCount="indefinite" values="0 150;42 150;42 150;42 150"/><animate attributeName="stroke-dashoffset" calcMode="spline" dur="1.5s" keySplines="0.42,0,0.58,1;0.42,0,0.58,1;0.42,0,0.58,1" keyTimes="0;0.475;0.95;1" repeatCount="indefinite" values="0;-16;-59;-59"/></circle><animateTransform attributeName="transform" dur="2s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/></g></svg>-->
        {{ form.loading ? "Please wait..." : "Confirm" }}
      </button>
    </div>
  </form>
</template>

<style scoped>
label {
  @apply text-lg font-semibold inline-block mb-2
}

input {
  @apply px-3 py-4 w-full border !text-zinc-900 border-primary bg-gray-100 rounded-xl focus:outline-none
}
</style>