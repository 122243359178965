<script setup lang="ts">
const step = useState('auth-step', () => 'default');

const stepIs = (value: string) => step.value === value;

onUnmounted(() => step.value = 'default');
</script>

<template>
  <div class="overflow-hidden">
    <transition name="slide-fade" mode="out-in">
      <auth-sign-up v-if="stepIs('default')" />
      <auth-register v-else-if="stepIs('register')" />
      <auth-sign-in v-else-if="stepIs('login')" />
      <auth-restore-password v-else-if="stepIs('reset-password')" />
    </transition>
  </div>
</template>

<style scoped>
.slide-fade-leave-active {
  transition: all .2s ease;
}

.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateY(10%);
  opacity: 0;
}
</style>